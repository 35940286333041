<template>
  <div>

    <!-- <div style="width:100px;height:100px;background-color:rebeccapurple;">abc</div> -->
    <van-overlay :show="showoverlay" @click="showoverlay = false" />
    <van-nav-bar
      title="登录"
      left-text="返回首页"
      left-arrow
      @click-left="back2Home"
    />
    <!-- <div class="card">
      <van-cell>
        <h2>手机验证码登录</h2>
      </van-cell>
    </div> -->
    <div>
      <div class="card">
        <van-row style="margin-top:5px">
          <van-col span="24">
            <van-field
              v-model="phone"
              type="tel"
              label="手机号(+86)"
              clearable
              required
              maxlength="11"
              placeholder="请输入手机号"
            >
              <!-- <van-icon slot="left-icon" name="https://b.yzcdn.cn/vant/icon-demo-1126.png" /> -->
            </van-field>

            <van-field
              v-model="code"
              type="tel"
              center
              clearable
              required
              label="短信验证码"
              placeholder="请输入短信验证码"
              maxlength="6"
            >
              <van-button
                v-if="!istimer"
                slot="button"
                size="small"
                color="#f2826a"
                type="primary"
                :disabled="!vfphone"
                @click="getCode()"
              >获取验证码</van-button>
              <van-button
                v-if="istimer"
                slot="button"
                size="small"
                color="#f2826a"
                type="primary"
                disabled
                @click="getCode()"
              >
                {{ "重发验证码"+second+"s" }}
              </van-button>
            </van-field>
            <!-- <van-icon slot="left-icon" name="https://b.yzcdn.cn/vant/icon-demo-1126.png" /> -->
          </van-col>
        </van-row>
      </div>
      <van-row type="flex" justify="center">
        <van-button color="#f2826a" :disabled="!isready2" style="width:50vw;font-size:1rem" @click="loginSms()">登    录</van-button>
        <!-- <van-button color="#f2826a" style="margin-right:20px" @click="toReg()">立刻注册</van-button> -->
      </van-row>
    </div>
  </div>
</template>
<script>
// import Vue from 'vue'
// const Base64 = require('js-base64').Base64

import { Tab, Tabs, Row, Icon, Col, Cell, CellGroup, Field, Toast, Overlay, Dialog, NavBar } from 'vant'
import * as sysTools from '../../utils/sysTools'
import { loginsmscode, loginv2, usedphonenumber } from '@/api/theApi'
export default {
	components: {
		[Tab.name]: Tab,
		[Tabs.name]: Tabs,
		[Row.name]: Row,
		[Icon.name]: Icon,
		[Col.name]: Col,
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Field.name]: Field,
		[Toast.name]: Toast,
		[Overlay.name]: Overlay,
		[NavBar.name]: NavBar
	},
	data() {
		return {
			active: 2,
			phone: '',
			code: '',
			pwd: '',
			istimer: false,
			usedPhone: true,
			lefttime: 0,
			second: 60,
			showoverlay: false,
			show: false
		}
	},
	computed: {
		isready2() {
			const pattern = /^1[3456789]\d{9}$/
			if (pattern.test(this.phone) && this.code.length === 6) {
				return true
			} else {
				return false
			}
		},
		theUA() {
			return sysTools.getUA()
		},
		isWeiXin() {
			if (this.theUA.indexOf('micromessenger') !== -1) {
				return true
			} else {
				return false
			}
		},
		vfphone() {
			const pattern = /^1[3456789]\d{9}$/
			// const isused =
			if (pattern.test(this.phone)) {
				return true
			}
			return false
		},
		canStartSmsLogin() {
			if (this.vfSmsCode() && this.vfphonenumber()) {
				return true
			} else {
				return false
			}
		}

	},
	created() {
		console.log(this.$route.query)
		const obj = this.$route.query
		if (Object(obj).hasOwnProperty('sw')) {
			if (obj.sw === '2') {
				this.active = 2
			} else {
				this.active = 1
			}
		} else {
			this.active = 2
		}
	},
	mounted() {

	},
	methods: {
		back2Home: function() {
			this.$router.push({
				path: '/'
			})
		},

		timeDiDa() {
			this.istimer = true

			const thetimer = setInterval(() => {
				--this.second
				// console.log(this.second)
				if (this.second < 0) {
					clearInterval(thetimer)
					this.istimer = false
					this.second = 60
				}
			}, 1000)
		},
		vfSmsCode() {
			// 如果 验证码长度不足 6位 ，返回false
			if (this.code.length !== 6) {
				return false
			}
			return true
		},
		vfphonenumber() {
			//  if (this.phone.length===11) {
			//   return true
			//  }
			const pattern = /^1[3456789]\d{9}$/
			// const isused =
			if (pattern.test(this.phone)) {
				return true
			}
			return false
		},

		async getCode() {
			const phone = await this.vfphonenumber()
			const isusedres = await usedphonenumber(this.phone)
			const isused = isusedres.data

			// console.log(isused)
			if (isused === 'false') {
				// console.log(isused)
				Toast.fail('未注册,请先注册')
				this.$router.push({
					path: '/regLogin'
				})
			}
			if (!phone) {
				Toast.fail('请检查手机号是否正确')
			}

			if (phone && isused === 'true') {
				this.timeDiDa()
				Toast.success('验证码已发送')
				loginsmscode(this.phone)
			}

			// if (phone && (!isused || isused === 'false') && this.lefttime - 60 <= 1) {
			// if (phone) {
			//   // this.lefttime = 25
			//   // this.istimer = true
			//   Toast.success('验证码已发送')
			//   getSmsCode(this.phone)
			// }
			// else {

			//   Toast.success('验证码已发送，有效期10分钟')
			//   // const smscode = await getSmsCode(this.phone)
			//   // console.log(smscode)
			// }
			// if (!this.vfphonenumber()) {
			//   // 未填写 手机号
			// } else {
			// }
		},

		backtohome() {
			this.$router.push({
				path: '/home'
			})
		},
		thelogin() {
			const phone = ''
			const code = ''
			loginv2(phone, code).then(
				res => {
					// if succ
					// url缓存信息跳转用户中心
					// else
					// 验证码错误
				}
			).catch(
				// 验证码错误
			)
		},
		loginSms() {
			const phone = this.phone
			const code = this.code
			const iv = this.$route.query.iv
			loginv2(phone, code).then(
				res => {
					if (res.login_status) {
						const encodeUname = sysTools.b64encode(res.username)
						const encodeUid = sysTools.b64encode(res.user_id)
						const url = '/user?un=' + encodeUname + '&suid=' + encodeUid + '&iv=' + iv
						try {
							localStorage.setItem('username', res.username)
							localStorage.setItem('login_status', res.login_status)
						} catch (error) {
							Dialog.alert(' IOS 版本不兼容，程序员小哥正在拼命修复')
						}
						this.$router.push({
							path: url
						})
					} else {
						Toast.fail('登录失败')
					}
				}
			).catch(() => {
				Toast.fail('登录失败')
			})
		},

		login2() {
			// const phone = this.phone
			// const pwd = this.pwd
			Toast.fail('暂停密码登录功能')
		}
	}

}
</script>
<style lang="less">
.van-nav-bar .van-icon {
    color: black;
    vertical-align: middle;
}
.van-nav-bar__text{
	color: black;
}
</style>
<style lang="less" scoped>
.dialogBg{
  background-color: transparent;
  img{
    z-index: 12;
    position: fixed;
  }
}
.card {
  margin:12px;
  padding: 2px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 8px 12px #ebedf0;
}
</style>
